<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Storages
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="storageCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
      >
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.site[item.status].value
          }}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <StorageModalForm
              :modalData="modalData"
              :statuses="statuses"
              :permissions="permissions"
              :userGroupCollection="userGroupCollection"
              :siteCollection="siteCollection"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></StorageModalForm>
            <v-spacer></v-spacer>

            <!-- <v-checkbox
              v-model="showAll"
              label="Active departments"
            ></v-checkbox> -->

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/stock/storages/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import StorageModalForm, { initialFormData } from "./StorageModalForm";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export const ENDPOINT = "stock/storage/";

export default {
  name: "Storages",
  components: { StorageModalForm },
  data() {
    return {
      showAll: false,
      search: "",
      permissions: [],
      headers: [
        {
          text: "Name",
          value: "name"
        },
        { text: "Status", value: "status" }
        // { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null
      },
      editedItem: Object.assign({}, initialFormData),

      dialogDelete: false
    };
  },

  computed: {
    ...mapGetters([
      "storageCollection",
      "statuses",
      "userGroupCollection",
      "siteCollection"
    ])
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function(id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true
    }
    // quantityUnitCollection(val) {
    //   this.filterCollection(val);
    // },
  },

  methods: {
    ...mapActions(["fetchStorage", "fetchUserGroups", "fetchSite"]),
    editItem(id) {
      this.modalData.editedId = id;
      this.modalData.dialog = true;
      // ApiService.get(ENDPOINT + id)
      //   .then(({ data }) => {
      //     this.editedItem = Object.assign({}, data);
      //     this.modalData.dialog = true;
      //   })
      //   .catch((error) => {
      //     console.log("Error!: ", error);
      //   });
    },

    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchStorage();
      // this.editedItem = Object.assign({}, initialFormData);
      this.modalData.editedId = null;
      if (this.$route.name !== "storages")
        this.$router.push({ name: "storages" });
    },

    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchStorage();
        })
        .catch(error => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/stock/storages/${item.id}`);
      }
    },

    closeDelete() {
      // this.editedItem = Object.assign({}, initialFormData);
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find(x => x.name == "stock.storage." + action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          item => item.name.indexOf("storage") > -1
        );
      }
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Storage" }]);
    this.fetchStorage();
    this.fetchUserGroups();
    this.fetchSite();
    this.setPermissions();
  }
};
</script>
