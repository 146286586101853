<template>
  <v-dialog v-model="modalData.dialog" max-width="650px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New storage
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col> -->
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.companySettings.department"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="formModel.name"
                  label="Name"
                  :rules="nameRules"
                  :id="dynamicID"
                  :error-messages="messages['name']"
                  @keyup="messages['name'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="formModel.user_group_id"
                  :items="userGroupCollection"
                  :rules="requiredRules"
                  label="Related user group"
                  item-text="groupName"
                  item-value="id"
                  :id="dynamicID"
                  :error-messages="messages['user_group_id']"
                  @keyup="messages['user_group_id'] = ''"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="formModel.site_id"
                  :items="siteCollection"
                  :rules="requiredRules"
                  label="Related site"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                  :error-messages="messages['site_id']"
                  @keyup="messages['site_id'] = ''"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { ENDPOINT } from "./Storages";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = {
  id: null,
  name: "",
  status: 1,
  user_group_id: null,
  site_id: null,
  translations: [],
};

export default {
  name: "StorageModalForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "userGroupCollection",
    "siteCollection",
  ],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData),
      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) => (!!v && v.length < 116) || "Field must be max 116 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id ? "Edit storage" : "New storage";
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        if (value.editedId) {
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.formModel = Object.assign({}, initialFormData);
          this.setTranslatedAttributes();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.resetErrorMessages();
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();
      if (!this.formValid) return;

      // console.error("this.formValid", this.formValid);
      if (this.formValid) {
        this.resetErrorMessages();
        this.modalData.loading = true;

        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model

          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    setTranslatedAttributes() {
      if (!this.formModel.id) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialFormData);
      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "stock.storage." + action);
    },
  },

  // async mounted() {
  //   this.setTranslatedAttributes();
  // },
};
</script>
